<template>
  <div class="GroupBy">
    <span class="filter-label">Group By:</span>
    <el-select
      v-model="groupBy"
      size="mini"
      default-first-option
      @change="applyFilter(groupBy)"
    >
      <template
        v-for="option in groupingOptions"
      >
        <el-option
          v-if="option.disabled === false"
          :key="option.value"
          :label="option.label"
          :value="option.value"
          :disabled="option.disabled"
        />
      </template>
    </el-select>
  </div>
</template>

<script>
export default {
  name: 'GroupBy',
  props: {
    groupingOptions: {
      type: Array,
      required: true,
    },
    defaultGroupBy: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    groupBy: '',
  }),
  created() {
    if (this.defaultGroupBy) {
      this.groupBy = this.defaultGroupBy;
    }
  },
  methods: {
    applyFilter(data) {
      this.$emit('apply', data);
    },
  },
};
</script>

<style lang="stylus" scoped>
  .GroupBy
    display grid
    grid-template-columns repeat(2, max-content)
    grid-gap 10px
    align-items center
</style>
